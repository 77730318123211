<template>
    <div class="invoice-administration vx-row">
        <div class="vx-col md:w-1/3 w-full mb-1" v-if="dataFetched">
            <vx-card title="Nummernzähler">
                <div class="vx-row mb-4 mt-0" v-for="(counter, index) in orderCounters">
                    <div class="vx-col w-1/2">
                      {{orderTypeName(counter.order_type_id)}}
                    </div>
                    <div class="vx-col w-1/2">
                        <vx-input-group class="mb-0">
                            <vs-input v-model="counter.count" @keyup.enter="updateOrderCounter(counter.order_type_id, counter.count, index)"/>
                            <template slot="append">
                                <div class="append-text btn-addon">
                                    <vs-button color="primary" @click="updateOrderCounter(counter.order_type_id, counter.count, index)">Speichern</vs-button>
                                </div>
                            </template>
                        </vx-input-group>
                    </div>
                </div>
            </vx-card>
        </div>
    </div>
</template>

<script>
    import ApiService from "../../../api";

    export default {
        name: "Invoice",
        data() {
            return {
                orderCounters: [],
                orderTypes: [],
                dataFetched: false
            }
        },
        created() {
            this.fetchData();
        },
        methods: {
            fetchData() {
                this.$vs.loading();

                const orderCounters = ApiService.get('/order_counters');
                const orderTypes = ApiService.get('/order_types');

                Promise.all([orderCounters, orderTypes]).then(result => {
                    if (result[0].status === 200) {
                        this.orderCounters = result[0].data.result;
                    }
                    if (result[1].status === 200) {
                        this.orderTypes = result[1].data.result;
                    }
                    this.dataFetched = true;
                    this.$vs.loading.close()
                }).catch(error => {
                    this.$vs.loading.close()
                });

            },
            orderTypeName(id) {
                const type = this.orderTypes.find(item => item.id === id);
                return type.name
            },
            updateOrderCounter(id, count, index) {
                this.$vs.loading();

                ApiService.put('/order_counters/' + id, {count: count}).then(response => {
                    if (response.status === 200) {
                        this.orderCounters[index].count = response.data.result.count;
                        this.orderCounters.push();

                        this.$vs.notify({
                            title: 'Erfolgreich',
                            text: 'Die Änderung wurde gespeichert',
                            iconPack: 'feather',
                            icon: 'icon-alert-circle',
                            color: 'success'
                        })

                    } else {
                        this.$vs.notify({
                            title: 'Fehler',
                            text: response.message,
                            iconPack: 'feather',
                            icon: 'icon-alert-circle',
                            color: 'danger'
                        })
                    }

                }).catch(error => {
                    this.$vs.notify({
                        title: 'Fehler',
                        text: error.message,
                        iconPack: 'feather',
                        icon: 'icon-alert-circle',
                        color: 'danger'
                    });
                }).finally(() => {
                    this.$vs.loading.close()
                });
            }
        }
    }
</script>

<style scoped>

</style>